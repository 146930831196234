
import NavLink from '@/components/links/NavLink.vue'

export default {
  name: 'Formats',
  components: { NavLink },
  props: {
    formats: {
      type: Array,
    },
    searchValue: {
      type: String,
    },
  },
  computed: {
    isAllFormatsView: {
      get() {
        return this.$store.getters['isAllFormatsView']
      },
    },
  },
  methods: {
    async setOtherFormats() {
      await this.$store.dispatch('addOtherFormats')
    },
  },
}
